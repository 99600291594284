import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import './inventory-details.styles.scss';
import { createStructuredSelector } from 'reselect';
import { selectScheduledInventory } from '../../redux/inventory/inventory.selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import InventoryReceiveForm from '../inventory-receive-form/inventory-receive-form.component';

const InventoryDetails = ({ location, scheduledInventory }) => {
    const [focussedID, setFocussedID] = useState(null);
    const [focussedInventory, setFocussedInventory] = useState(null);
    const masked_fields = ['quantity', 'master-carton-qty', 'quantity_per_carton', 'weight'];

    useEffect(() => {
        setFocussedID(queryString.parse(location.search).id);
    }, [location]);

    useEffect(() => {
        setFocussedInventory(_.find(scheduledInventory, { id: focussedID }));
    }, [scheduledInventory, focussedID]);

    return (
        <div>
            {!focussedID ? (
                <h2>Select an inventory to view details and record receiving</h2>
            ) : (
                <React.Fragment>
                    {!!focussedInventory && (
                        <div>
                            {Object.keys(focussedInventory).map((key, index) => {
                                if(!masked_fields.includes(key)) {
                                    return (
                                        <p key={index}>
                                        {/* TODO yeh utils mai jaa skta hai*/}
                                            <span className="detail-label">{key.replace(/_/g, ' ')}: </span>
                                            {focussedInventory[key].replace(/_/g, ' ')}
                                        </p>
                                    );
                                }
                            })}
                            <InventoryReceiveForm focussedInventory={focussedInventory} />    
                        </div>
                    ) }
                </React.Fragment>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    scheduledInventory: selectScheduledInventory
});

export default compose(
    connect(mapStateToProps),
    withRouter
)(InventoryDetails);