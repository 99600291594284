import React, { useEffect, useState } from 'react';
import {
    Table,
    Input,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Modal,
    ModalBody,
    Alert,
    ModalFooter
  } from 'reactstrap';
import { connect } from 'react-redux';
import { getQueries, markTicket, addResponse, uploadFile, getFile } from '../redux/queries/queries.actions';
import { Link } from 'react-router-dom';
import PageSpinner from './PageSpinner';
import placementSuccess from '../assets/images/placement-success.gif';
import placementLoader from '../assets/images/placement-loader.gif';
  
  
const ShowQueries = ({ client_ID, showQueries, existingqueries, progress, markQuery, newResponse, addFile, getLink }) => {

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [modalquery, setModalquery] = useState({"responses":{}});
    const [actionOngoing, setAction] = useState(false);

    const isLoading = existingqueries===null;
    const noQueries = (!isLoading && Object.keys(existingqueries).length===0);
    const inProgress = (progress!=="FINISHED" && progress!=="");
    const isFinished = progress==="FINISHED";

    useEffect(() => {
        showQueries();
    }, [showQueries])

    const handleQueryinfo = (e) => {
        const modaldata = existingqueries[e.target.value];
        modaldata["queryID"] = e.target.value;
        setModalquery(modaldata);
        toggle();
    }

    const handleMark = () => {        
        markQuery(modalquery["status"], modalquery["queryID"]);
        setAction(true);
    }

    const handleResponse = () => {
        const myresponse = document.getElementById('input_response').value; 
        if(myresponse==="" || myresponse===" ") window.alert('Please Enter your response.');
        else {
            newResponse(myresponse, `vendors/users/${client_ID}/queries/${modalquery["queryID"]}/responses/`, false);
            setAction(true);
        }
    }

    const handleUpload = () => {
        const $uploadfile = document.getElementById("input_upload").files[0];
        if($uploadfile!==undefined)
        {
            const name = "Attachment - " + $uploadfile.name;
            const responsedata ={
                "name": name,
                "location": `vendors/users/${client_ID}/queries/${modalquery["queryID"]}/responses/`,
                "admin": false
            }
            addFile($uploadfile, `vendors/${client_ID}/queries/${modalquery["id"]}/`, responsedata);
            setAction(true);
        }
        else window.alert("Please select a file to upload");
    }

    const handleOpen = (e) => {
        getLink(`vendors/${client_ID}/queries/${modalquery["id"]}/`, e.target.value);
    }

    const handleReload = () => {
        setAction(false);
        toggle();
        showQueries();
    }

    const getDate = (timestamp) => {
        const mydate = new Date(timestamp);
        return mydate.toDateString().replace(" ",", ");
    }

    const gettime = (timestamp) => {
        const mydate = new Date(timestamp);
        const hrs = mydate.getHours()<10?"0"+mydate.getHours():mydate.getHours();
        const mins = mydate.getMinutes()<10?"0"+mydate.getMinutes():mydate.getMinutes();
        const secs = mydate.getSeconds()<10?"0"+mydate.getSeconds():mydate.getSeconds();
        return (hrs+":"+mins+":"+secs);
    }

    const categorylist = {
        "orders": "Orders",
        "inventory": "Inventory",
        "lastmile": "Last Mile Connect",
        "others": "Miscellaneous"
    }

    return(
        <div>
            <Card>
                <CardHeader className="h3">
                    EXISTING QUERIES
                    <Link to="/help/ticket"><Button className="float-right" color="secondary">Create New Ticket</Button></Link>
                </CardHeader>
                <CardBody>
                    {isLoading && (<PageSpinner />)}
                    {noQueries && (<Alert color="danger">No Record Found</Alert>)}
                    {(!isLoading && !noQueries) && (
                    <Table>
                        <thead className="bg-light">
                            <tr>
                                <th>ID</th>
                                <th>Subject</th>
                                <th>Category</th>
                                <th>Status</th>
                                <th>Date Added</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {!isLoading && (Object.keys(existingqueries).map(key => 
                            <tr key={key}>
                                <td style={{verticalAlign: "middle"}}>
                                    {existingqueries[key]["id"]}
                                </td>
                                <td style={{verticalAlign: "middle"}}>
                                    {existingqueries[key]["question"]}
                                </td>
                                <td style={{verticalAlign: "middle"}}>
                                    {categorylist[existingqueries[key]["category"]]}
                                </td>
                                <td style={{verticalAlign: "middle"}}>
                                    {existingqueries[key]["status"]==="unsolved"?"Under-Review":"Closed"}
                                </td>
                                <td style={{verticalAlign: "middle"}}>
                                    {getDate(existingqueries[key]["time"])}
                                </td>
                                <td style={{verticalAlign: "middle"}}>
                                    <Button color="primary" value={key} onClick={handleQueryinfo}>View Ticket</Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    )}
                </CardBody>
            </Card>
            <Modal isOpen={modal} toggle={toggle} centered={true} size="lg">
                <ModalBody>
                    <Card>
                        <CardHeader className="h5 bg-light">
                            {modalquery["question"]}<br />
                            <div className="float-right">- {getDate(modalquery["time"])} ({modalquery["id"]})</div>
                        </CardHeader>
                        <CardBody style={{maxHeight: 350, overflow: "auto"}}>
                            {Object.keys(modalquery["responses"]).map(el => 
                            <div key={el} 
                                 className={modalquery["responses"][el]["sender"]==="client"?
                                    "d-flex flex-row-reverse":"d-flex"}>
                                <div style={{width: '50%'}}
                                     className={modalquery["responses"][el]["sender"]==="client"?
                                        "table-secondary rounded p-2":"table-primary rounded p-2"}>
                                    <b>{modalquery["responses"][el]["sender"]==="client"?"YOU":"ADMIN"}</b><br />
                                    {modalquery["responses"][el]["response"]}
                                    {modalquery["responses"][el]["response"].match(/Attachment - /)===null?"":
                                        <Button 
                                            size="sm" color="warning" onClick={handleOpen}
                                            value={modalquery["responses"][el]["response"].replace(/Attachment - /, "")}
                                        >Open</Button>}
                                        <div className="float-right"><b>
                                            {getDate(modalquery["responses"][el]["time"])+', '+gettime(modalquery["responses"][el]["time"])}
                                        </b></div>
                                </div>
                            </div>
                            )}
                        </CardBody>
                        <CardFooter className="bg-light">
                            {(modalquery["status"]==="unsolved") && (
                                <div>
                                    <Input
                                        type="textarea"
                                        name="response"
                                        placeholder="Enter your response here"
                                        id="input_response"
                                    />
                                    <center>
                                        <Button color="info" onClick={handleResponse} className="mt-2">Submit Response</Button>
                                    </center>
                                    <div className="mt-2"> 
                                        <input type="file" accept="image/*,.pdf" id="input_upload" />{' '}
                                        <Button className="float-right" color="primary" onClick={handleUpload}>Upload File</Button>
                                    </div>
                                </div>
                            )}
                            {(modalquery["status"]==="solved") && (
                                <div className="h5">
                                    This ticket has been closed.{' '}
                                    <Button className="float-right" color="info" onClick={handleMark}>Re-Open Ticket</Button>
                                </div>
                            )}
                        </CardFooter>
                    </Card>
                </ModalBody>
                <Button onClick={toggle}>Close</Button>
            </Modal>
            {inProgress && (
                <Modal isOpen={true} backdrop="static" centered={true}>
                    <center>
                        <img src={placementLoader} alt="placement loader gif" width="180" height="180" />
                        <h4>Please wait...</h4>
                    </center>
                </Modal>
            )}
            {isFinished && (
                <Modal isOpen={actionOngoing} centered={true}>
                    <ModalBody>
                        <center>
                            <img 
                                src={placementSuccess} 
                                alt="placement success gif" 
                                width="260" height="260"
                                style={{padding: "20px 0px"}}
                            />
                            <h4 style={{marginBottom: "10px"}}>Success!</h4>
                        </center>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleReload}>Go back</Button>
                    </ModalFooter>
                </Modal>
            )}
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    showQueries: () => 
        dispatch(getQueries()),

    markQuery: ($current, $campaignID, $queryID) => 
        dispatch(markTicket($current, $campaignID, $queryID)),

    newResponse: ($response, $location, $admin) => 
        dispatch(addResponse($response, $location, $admin)),

    addFile: ($filedata, $location, $responsedata) => 
        dispatch(uploadFile($filedata, $location, $responsedata)),

    getLink: ($location, $name) => dispatch(getFile($location, $name))
});

const mapStateToProps = (state) => ({
    existingqueries: state.queries.existingqueries,
    progress: state.queries.progress,
    client_ID: state.auth.uid
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowQueries);