import inventoryActionTypes from './inventory.types';

const INITIAL_STATE = {
    status: null,
    isFetching: false,
    isInventoryReceived: false,
    errorMessage: undefined
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case inventoryActionTypes.FETCH_INVENTORY_START:
        case inventoryActionTypes.INVENTORY_RECEIVE_START:
            return {
                ...state,
                isFetching: true,
                isInventoryReceived: false
            }

        case inventoryActionTypes.FETCH_INVENTORY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                status: action.payload
            }

        case inventoryActionTypes.INVENTORY_RECEIVE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isInventoryReceived: true
            }

        case inventoryActionTypes.FETCH_INVENTORY_FAILURE:
        case inventoryActionTypes.INVENTORY_RECEIVE_FAILURE:
            return {
                ...state,
                isFetching: false,
                isInventoryReceived: false,
                errorMessage: action.payload
            }

        default:
            return state;
    }
}