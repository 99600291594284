import { storage } from '../../firebase/firebase';

//SET_UPLOAD_PROGRESS
export const setUploadProgress = (uploadProgress = 0) => ({
    type: 'SET_UPLOAD_PROGRESS',
    uploadProgress
});

//SET_UPLOAD ALERT
export const setUploadAlert = (uploadAlert = false) => ({
    type: 'SET_UPLOAD_ALERT',
    uploadAlert
});

//SET_IS_UPLOADED
export const setIsUploaded = (isUploaded = false) => ({
    type: 'SET_IS_UPLOADED',
    isUploaded
});

export const startFileUpload = (fileName, storageLocation, callback) => {
    return (dispatch, getState) => {
        const $uploadElement = document.getElementById('upload-input');

        let file = $uploadElement.files[0];
        var blob = file.slice(0, file.size, 'application/pdf');
        file = new File([blob], fileName, {type: 'application/pdf'});

        const uploadRef = storage.ref(storageLocation).child(file.name);
        const uploadTask = uploadRef.put(file);
        uploadTask.on('state_changed', (snapshot) => {
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch(setUploadProgress(progress));
        }, (err) => {
            console.log(err);
            dispatch(setUploadAlert(true));
            setTimeout(() => {
               dispatch(setUploadAlert(false));
            }, 3000)
        }, () => {
            dispatch(setIsUploaded(true));
            dispatch(setUploadProgress())
            callback();
        });
    }
};