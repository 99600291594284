const defaultState = {
    uploadAlert: false,
    isUploaded: false,
    uploadProgress: 0
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_UPLOAD_PROGRESS':
            return {
                ...state,
                uploadProgress: action.uploadProgress 
            }

        case 'SET_UPLOAD_ALERT':
            return {
                ...state,
                uploadAlert: action.uploadAlert
            }

        case 'SET_IS_UPLOADED':
            return {
                ...state,
                isUploaded: action.isUploaded
            }

        default:
            return state;
    }
}