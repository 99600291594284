import { firebase } from '../../firebase/firebase';

export const login = (uid) => ({
    type: 'LOGIN',
    uid
});

export const startLogin = (email, password) => {
    return () => {
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then(({ user } )=> {
            user.getIdTokenResult()
            .then(idTokenResult => {
                if (!idTokenResult.claims.vendor) {
                    alert('Login Failed');
                }
            })
        })
        .catch(err => alert('Login Failed'));
    }
};

export const logout = () => ({
    type: 'LOGOUT'
});

export const startLogout = () => {
    return () => {
        return firebase.auth().signOut();
    };
};