
const firebaseConfig = require('../firebase-config.json').result.sdkConfig;

const cfURL = `https://asia-east2-${firebaseConfig.projectId}.cloudfunctions.net`

const HOSTING_ENV = process.env.NODE_ENV === 'production' ? cfURL : `http://localhost:5000/${firebaseConfig.projectId}/asia-east2`;


const withHostingEnv = functionName => `${HOSTING_ENV}/${functionName}`;

export default {
    dbInventoryManagementOnReadVendorInventory : withHostingEnv('dbInventoryManagementOnReadVendorInventory'),
    dbInventoryManagementOnInventoryReceive: withHostingEnv('dbInventoryManagementOnInventoryReceive')
};
