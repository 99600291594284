import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

import ProgressBar from './ProgressBarComponent';

const UploadProcess = ({ uploadProgress, uploadAlert }) => {

    return (
        <div>
        { (uploadProgress !== 0 && uploadProgress !== 100) && (
            <div className="waybill-panel__progress-status">
                <ProgressBar value={uploadProgress} />
                <span className="text-secondary">{Math.round(uploadProgress)} %</span>
            </div>) }
        { uploadProgress === 100 && <Alert color="success" className="waybill-panel__upload-success-alert">File Uploaded Successfully</Alert> }
        { uploadAlert && <Alert color="danger">File upload failed!</Alert> }
        </div>
    );
};

UploadProcess.propTypes = {
    uploadProgress: PropTypes.number.isRequired,
    uploadAlert: PropTypes.bool
};

export default UploadProcess;