import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import { 
        Page, 
        Text, 
        Image, 
        View, 
        Document, 
        StyleSheet,
        pdf
        } from '@react-pdf/renderer';
import { Button, Input } from 'reactstrap';
import Logo from '../assets/images/logo.png';

const Warehouse = ({ product, quantity, company, person }) => {


    const [inputState, setInputState] = useState({});

    useEffect(() => {
        setInputState({
            input_p: person,
            input_q: quantity,
            input_n: ''
        })
    }, [setInputState, person, quantity])

    const handleChange = e => {
        const { name, value } = e.target;
        if (name === 'input_n' && !validator.isNumeric(value) && value !== '') {
            return;
        }
        setInputState({
            ...inputState,
            [name]: value
        });
    }

    const handlePDF = (typevar) => {

        const { input_p, input_q, input_n } = inputState;
        
        if(input_n==="") window.alert('Please Enter a phone number');
        else {

        const d = new Date();
        const n = d.getDate();
        const m = d.getMonth()+1;
        const y = d.getFullYear();

        const styles = StyleSheet.create({
            bigtext: {
                fontSize: 22
            },

            address: {
                marginTop: 20,
                width: 220,
                position: "absolute",
                right: 0,
                fontSize: 10,
                lineHeight: 1.7
            },

            heading: {
                textAlign: 'center', 
                fontFamily: 'Times-Bold',
                fontSize: 28
            },

            maintext: {
                width: 536,
                fontSize: 16,
                flexDirection: 'row',
                flexWrap: 'wrap'
            },

            doc: {
                margin: 20
            },

            picture: {
                width: 270,
                height: 90
            },

            viewer: {
                width: 1400,
                height: 700
            },

            footer: {
                position: "absolute",
                bottom: 240
            },

            stamp: {
                fontSize: 14,
                position: "absolute",
                bottom: -20,
                left: 90
            },

            number: {
                fontSize: 14,
                position: "absolute",
                bottom: -60,
                left: 10
            }
        });
         
          const MyDocument = (
            <Document>
              <Page size="A4" style={styles.doc}>
                <Image style={styles.picture} src={Logo} />
                <Text style={styles.bigtext}>Sampling Solution Pvt. Ltd.</Text>
                <View style={styles.address}>
                    <Text>Registered Office Address:</Text>
                    <Text>69 SFS Rajouri Apartments, Rajouri</Text>
                    <Text>Garden, Delhi - 110064</Text>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={{fontFamily: 'Times-Bold'}}>Official Website: </Text> 
                        <Text style={{color: 'blue'}}>www.freestand.in</Text>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={{fontFamily: 'Times-Bold'}}>Contact Number: </Text> 
                        <Text>+91-9650038959</Text>
                    </View>
                </View>
                <Text style={styles.heading}>{"\n"}Recieving Document{"\n\n"}</Text>
                <View style={styles.maintext}>
                    <Text>This document states that consignment consisting </Text>
                    <Text style={{fontFamily: 'Times-Bold'}}> {product.replace(/\s-\sPACKAGING\sSLIPS/,"")} </Text>
                    <Text> in quantity of </Text>
                    <Text style={{fontFamily: 'Times-Bold'}}> {input_q} units </Text>
                    <Text>has been handed over to Miss/Mr. </Text>
                    <Text style={{fontFamily: 'Times-Bold'}}> {input_p.toUpperCase()} </Text>
                    <Text> of </Text>
                    <Text style={{fontFamily: 'Times-Bold'}}> {company.toUpperCase()}.{"\n\n\n"}</Text>
                </View>
                <View style={styles.maintext}>
                    <Text>She/He have received the consignment and shall abide by the guidelines stated in the MoU (Enclosed).</Text>
                </View>
                <View style={styles.footer}>
                <Text>Date:           {n}/{m}/{y}{"\n\n\n\n\n\n\n"}</Text>
                    <Text style={{fontSize: 14}}>
                        ______________________________              [Freestand Sampling Solution Pvt. Ltd.]
                    </Text>
                    <Text style={styles.stamp}>Signature</Text>
                    <Text style={styles.number}>Mobile No: {input_n}</Text>
                </View>
              </Page>
            </Document>
          );

          pdf(MyDocument).toBlob().then(val => {
              if(typevar==="download") {
                document.getElementById('mypdflink').href = (URL.createObjectURL(val));
                document.getElementById('mypdflink').click();
              }
              else window.open(URL.createObjectURL(val));
            });
        }
    }

    return (
        <div>
        <Input name="input_p" value={inputState.input_p || ''} type="text" placeholder="Person Recieving" onChange={handleChange} /><br />
        <Input name="input_q"  value={inputState.input_q || 1} type="number" min="1" placeholder="Enter Quantity" onChange={handleChange} /><br />
        <Input name="input_n" type="text" placeholder="Phone Number" value={inputState.input_n || ''} onChange={handleChange} /><br />
        <Button color="primary" onClick={() => handlePDF("download")}>Download PDF</Button>{' '}
        <Button color="primary" onClick={() => handlePDF("generate")}>Generate PDF</Button>
        <a id="mypdflink" href="" download="recieving.pdf" style={{display: 'none'}}></a>
        </div>
    );
}

const mapStateToProps = (state) => ({
    product: state.vendor.selectedRecord.packaging_slips + "_" + state.vendor.selectedRecord.sku,
    quantity: state.vendor.selectedRecord.waybill_count,
    person: state.vendor.profile.name,
    company: state.vendor.profile.vendor_id
});

export default connect(mapStateToProps)(Warehouse);
