import { combineReducers } from 'redux';

import authReduecer from '../auth/auth.reducer';
import formReducer from '../form/form.reducer';
import vendorReducer from '../vendor/vendor.reducer';
import inventoryReducer from '../inventory/inventory.reducer';
import queriesReducer from '../queries/queries.reducer';
import tasksReducer from '../tasks/tasks.reducer';
import firebaseReducer from '../firebase/firebase.reducers';
import statsReducer from '../inventoryform/inven.reducer';

const rootReducer = combineReducers({
    auth: authReduecer,
    form: formReducer,
    vendor: vendorReducer,
    inventory: inventoryReducer,
    queries: queriesReducer,
    tasks: tasksReducer,
    firebase: firebaseReducer,
    inventoryform: statsReducer
});

// TODO Add redux-persist here if need be

export default rootReducer;