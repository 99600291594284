import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Input } from 'reactstrap';
import { connect } from 'react-redux';

import { SetSelectedClient } from '../../redux/vendor/vendor.actions';

const ControlPanel = ({ profile, setClient, onComplete }) => {

    const [selClient, setSelClient] = useState(profile.client_id);

    const handleClient = (e) => {
        setSelClient(e.target.value);
    }

    const handleCamp = (e) => {
        if(e.target.value!="-1"){
            setClient(selClient, e.target.value);
            onComplete();
        }
    }

    return (
        <Card>
            <CardHeader>
                Control Panel
            </CardHeader>
            <CardBody>
                <Row>
                    <Col>
                        Choose Client:
                        <Input defaultValue={profile.client_id} type="select" onChange={handleClient}>
                            <option value="-1">Select Client</option>
                            {Object.keys(profile.clients).map(uid =>
                                <option key={uid} value={uid}>
                                    {profile.clients[uid]['name']}
                                </option>
                            )}
                        </Input>
                    </Col>
                    <Col>
                        Choose Campaign:
                        <Input defaultValue={profile.campaign} type="select" id="sel_camp" onChange={handleCamp}>
                            <option value="-1">Select Campaign</option>
                            {profile.clients[selClient] && profile.clients[selClient]['campaigns'].map(camp => 
                                <option key={camp} value={camp}>
                                    {camp}
                                </option>
                            )}
                        </Input>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setClient: (uid, c) => dispatch(SetSelectedClient(uid, c))
});

const mapStateToProps = (state) => ({
    profile: state.vendor.profile
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
