/* 
    Name of Component: Odometer Component
    Purpose: Sets up odometer component with a given format
    @dependencies: react-odometerjs
    @params:
        value: Number
*/

import React from 'react';
import PropTypes from  'prop-types';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';

const OdometerComponent = ({ value }) => (
    <Odometer 
        format="d"
        value={ value }
    />
);

OdometerComponent.propTypes = {
    value: PropTypes.number.isRequired
}

export default OdometerComponent;