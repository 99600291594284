/*
    Name of Component: Progress Bar Component
    Purpose: Can be used to display upload percentage
    @params:
        value: Number
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';

const ProgressBarComponent = ({ value }) => {
    return (
        <div className="mb-2 ml-1 mt-2" style={{ width: '75%' }}>
            <Progress striped animated color="success" value={value.toString()} />
        </div>
    );
};

ProgressBarComponent.propTypes = {
    value: PropTypes.number.isRequired
};

export default ProgressBarComponent;