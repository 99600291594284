import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth'; 

let firebaseConfig = require('../firebase-config.json').result.sdkConfig;


// Fixing my f'up here, changing default database url
firebaseConfig.databaseURL = firebaseConfig.databaseURL.replace('https://freestand-backend-dev-default-rtdb.europe-west1.firebasedatabase.app', 'https://freestand-backend-dev.firebaseio.com')


firebase.initializeApp(firebaseConfig);

const database = firebase.database()
const storage = firebase.storage();

export { firebase, storage, database as default };