import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import componentQueries from 'react-component-queries';
import PrivateRoute from './PrivateRoute';
import MainLayout from '../components/Layout/MainLayout';
import '../styles/reduction.scss';

import AuthPage from '../pages/AuthenticationPage';
import VendorDashboard from '../pages/VendorDashboard';
import InventoryReceivalPage from '../pages/inventory-receival/inventory-receival-page.component';
import InventoryUpdateForm from '../pages/InventoryUpdateForm';
import SupportPage from '../pages/SupportPage';
import TicketPage from '../pages/TicketPage';
import TasksPage from '../pages/TasksPage';

export const history = createBrowserHistory();

const AppRouter = ({ breakpoint }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={AuthPage} />
        <MainLayout breakpoint={breakpoint}>
          <PrivateRoute path="/dashboard" component={VendorDashboard} />        
          <PrivateRoute path="/inventory/receival" component={InventoryReceivalPage} /> 
          {/* <PrivateRoute path="/inventory" component={InventoryUpdateForm} />  */}
          <PrivateRoute exact path="/help" component={SupportPage} />
          <PrivateRoute exact path="/help/ticket" component={TicketPage} />
        </MainLayout>
      </Switch>
    </Router>
  );
};

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(AppRouter);