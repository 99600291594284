import React, { useState } from 'react';
import { connect } from 'react-redux';
import { startLogin } from '../redux/auth/auth.actions';

import FormInput from './FormInput';

import CustomButtonComponent from './CustomButton';

const Signin = ({ startLogin }) => {
    const [loginCredentials, setLoginCredentials] = useState({
        email: '',
        password: ''
    });

    const handleChange = (e) => {
        const { value, name } = e.target;
        setLoginCredentials({
            ...loginCredentials,
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const { email, password } = loginCredentials;
        startLogin(email, password);
    }

    return (
        <div className="sign-in">
            <h3>Sign in with alloted email and password</h3>

            <form onSubmit={handleSubmit}>
                <FormInput
                 name="email"
                 type="email" 
                 label="Email"
                 value={loginCredentials.email} 
                 handleChange={handleChange}
                 required 
                />
                <FormInput
                 name="password" 
                 type="password" 
                 label="Password"
                 value={loginCredentials.password} 
                 handleChange={handleChange}
                 required
                />
                <CustomButtonComponent type="submit"> Sign in </CustomButtonComponent>
            </form>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    startLogin: (email, password) => dispatch(startLogin(email, password))
});

export default connect(undefined, mapDispatchToProps)(Signin);