const defaultState = {
    assets: [],
    profile: {
        campaign: '',
        client_id: '',
        clients: {},
        name: '',
        position: '',
        region: '',
        vendor_id: ''
    },
    selectedRecord: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case 'SET_VENDOR_ASSETS':
            return {
                ...state,
                assets: action.assets
            }

        case 'SET_IS_LOADING': 
            return {
                ...state,
                isLoading: action.isLoading
            }

        case 'SET_USER_PROFILE':
            return {
                ...state,
                profile: action.profile
            }

        case 'SET_SELECTED_RECORD':
            return {
                ...state,
                selectedRecord: action.selectedRecord
            }

        default:
            return state;
    }
}