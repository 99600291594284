import { FIREBASE_AUTH } from './firebase.types';

// This action is beign watched by a saga. Go to sagas file
export const loadFirebaseAuthToken = () => ({
    type: FIREBASE_AUTH.LOAD
});

export const setFirebaseAuthToken = (token) => ({
    type: FIREBASE_AUTH.SUCCESS,
    payload: token
});

export const setFirebaseAuthError = (error) => ({
    type: FIREBASE_AUTH.FAIL,
    payload: error
});