import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import Axios from 'axios';

import inventoryActionTypes from './inventory.types';
import { fetchInventorySuccess, fetchInventoryFailure, inventoryReceiveSuccess, inventoryReceiveFailure } from './inventory.actions';
import { selectVendorProfile } from '../vendor/vendor.selectors';

import { selectFirebaseToken } from '../firebase/firebase.selectors';
import cf from '../../firebase/cf';

export function* fetchInventoryAsync() {
    try {
        const token = yield select(selectFirebaseToken);
        const { vendor_id, region } = yield select(selectVendorProfile);
        const warehouse_code = vendor_id + '_' + region;
        const { data } = yield Axios({
            method: 'GET',
            url: cf.dbInventoryManagementOnReadVendorInventory,
            params: {
                warehouse_code
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        yield put(fetchInventorySuccess(data));
    } catch (err) {
        yield put(fetchInventoryFailure(err.message));
    }
}

export function* inventoryReceiveStartAsync({ payload }) {
    try {
        
        const token = yield select(selectFirebaseToken);
        const { client_id, region, vendor_id, campaign } = yield select(selectVendorProfile);
        yield Axios({
            method: 'POST',
            url: cf.dbInventoryManagementOnInventoryReceive,
            data: {
                client_id,
                campaign,
                city: region,
                warehouse: `${vendor_id}_${region}`,
                formData: payload
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });        
        yield put(inventoryReceiveSuccess());
    } catch (err) {
        yield put(inventoryReceiveFailure(err.message));
    }
}

export function* fetchInventoryStart() {
    yield takeLatest(
        inventoryActionTypes.FETCH_INVENTORY_START,
        fetchInventoryAsync
    );
}

export function* inventoryReceiveStart() {
    yield takeLatest(
        inventoryActionTypes.INVENTORY_RECEIVE_START,
        inventoryReceiveStartAsync
    );
}

export function* inventorySagas() {
    yield all([
        call(fetchInventoryStart),
        call(inventoryReceiveStart)
    ])
}