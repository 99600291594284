import database, { storage, firebase } from '../../firebase/firebase';
//import { addTask, addNotif } from './tasks';
// import emailjs from 'emailjs-com';

//SET_PROGRESS
export const setProgress = (progress) => ({
    type: 'SET_PROGRESS',
    progress
})

//ADD_RESPONSE
export const addResponse = (response, location, admin) => {
    return (dispatch) => {
        database.ref(location).push({
                "response":response,
                "sender":admin?"admin":"client",
                "time": firebase.database.ServerValue.TIMESTAMP
            }).then(() => {
                    const $tdate = new Date();
                    const notifdata = {
                        "name": "Query Text message",
                        "description": `The query has a text response from the client. Please check the help panel for more details.`,
                        "category": "Help & Support",
                        "campaign": "",
                        "priority": "high",
                        "date": $tdate.getTime(),
                        "sticky": false,
                        "byline": "Freestand"
                    }
                    // dispatch(addNotif(notifdata));
                    dispatch(setProgress("FINISHED"));
                }
        ).catch(err => console.log(err));
    }
}

//GET_FILE
export const getFile = (storageLocation, name) => {
    return () => {
        storage.ref(storageLocation).child(name).getDownloadURL().then(url => window.open(url)
        ).catch(err => console.log(err));
    }
}

//ADD_QUERY
export const addQuery = (querydata, response, filedata="") => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        let rootRef = "";
        rootRef = `vendors/users/${clientID}/queries/`;
        const myID = database.ref(rootRef).push().key;
        querydata['time'] = firebase.database.ServerValue.TIMESTAMP;
        database.ref(rootRef + myID).set(querydata).then(() => {
            dispatch(setProgress("New Query Added."));
            database.ref(rootRef + myID + `/responses/`).push({
                "response":response,
                "sender":"client",
                "time": firebase.database.ServerValue.TIMESTAMP
                }).then(() => {
                    dispatch(setProgress("Created Responses."));
                    if(filedata!==""){
                        const responsedata ={
                            "name": "Attachment - " + filedata["name"],
                            "location": rootRef + `${myID}/responses/`,
                            "admin": false
                        }
                        dispatch(uploadFile(filedata, `${clientID}/queries/${querydata["id"]}/`, responsedata));
                    }
                    else dispatch(setProgress("FINISHED"));

                    //AUTOMATED EMAIL SENDING CODE
                    const html_message = `
                        <div style="background-color: #292b2c; border-radius: 10px; padding: 10px; text-align: center;">
                            <span style="font-size: 26; color: #f7f7f7;">Ticket Created (#${querydata["id"]})</span>
                        </div>
                        <div style="background-color: #5bc0de; border-radius: 10px; padding: 10px; text-align: center;">
                            <span style="font-size: 18; color: #292b2c;">
                                Your query has been recorded, we are very sorry for any inconvinience cause<br>
                                We assure you, we are working on it and will get back to you latest within 2 working days.<br>
                                Please check the help section of the dashboard for any responses and feel free to follow-up through the same.
                            </span>
                            <div style="margin: 30px; border: solid black 3px; padding: 10px;">
                                <span style="font-size: 26; color: #292b2c;">
                                    <b>Ticket-ID:</b><br> ${querydata["id"]}<br><br>
                                    <b>Subject:</b><br> ${querydata["question"]}<br><br>
                                    <b>Query:</b><br> ${response}<br>
                                </span>
                            </div>
                        </div>`;

                    const template_params = {
                        "ticket_id": querydata["id"],
                        "email_to": querydata["email"],
                        "message": html_message
                    }

                    // emailjs.send('lakshay_fs', 'ticket_created', template_params, 'user_FeIPBp5uhEup17TlsQ4bV')
                    // .then((result) => {
                    //     console.log(result.text);
                    // }, (error) => {
                    //     console.log(error.text);
                    // });
                    //CODE FOR EMAIL ENDS.
                    const $tdate = new Date();
                    const taskdata ={
                        "name": "Query Recorded",
                        "description": `Your query (#${querydata["id"]}) has been recorded. Please check the help panel for more details.`,
                        "category": "Help & Support",
                        "campaign": querydata["campaign"]==="nocampaign"?"":querydata["campaign"],
                        "priority": "low",
                        "date": $tdate.getTime(),
                        "sticky":false,
                        "byline": "Freestand"
                    }
                    // dispatch(addTask(taskdata));
                    const notifdata ={
                        "name": "New Query Submitted",
                        "description": `New query (#${querydata["id"]}) has been recorded. Please check the panel for more details.`,
                        "category": "Help & Support",
                        "priority": "high",
                        "date": $tdate.getTime(),
                        "sticky":false
                    }
                    // dispatch(addNotif(notifdata));
                }
            ).catch(err => console.log(err));}
        ).catch(err => console.log(err));
    }
}

//UPLOAD_FILE
export const uploadFile = (filetoupload, storageLocation, responsedata) => {
    return (dispatch) => {
        const uploadRef = storage.ref(storageLocation).child(filetoupload.name);
        const uploadTask = uploadRef.put(filetoupload);
        uploadTask.on('state_changed', () => {
            dispatch(setProgress("Uploading..."));
        }, (err) => {
            console.log(err);
        }, () => {
            dispatch(setProgress("UPLOADED!"));
            dispatch(addResponse(responsedata["name"], responsedata["location"], responsedata["admin"]));
        });
    }
}

//GET_QUERIES
export const setQueries = (existingqueries) => ({
    type: 'GET_QUERIES',
    existingqueries
})

export const getQueries = () => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        const existingqueries = {};
        database.ref(`vendors/users/${clientID}/queries/`).once('value').then(snapshot => {
            if (snapshot.exists()) {
                const queriesall = snapshot.val();
                Object.keys(queriesall).forEach(queryid => {
                    existingqueries[queryid] = queriesall[queryid];
                }); 
            }
            dispatch(setQueries(existingqueries));
        }).catch(err => console.log(err));
    }
}

//MARK_UNSOLVED
export const markTicket = (current, queryID) => {
    return (dispatch, getState) => {
        const clientID = getState().auth.uid;
        const updateobject = {};
        if(current==="unsolved") updateobject["status"] = "solved";
        else updateobject["status"] = "unsolved";
        database.ref(`vendors/users/${clientID}/queries/${queryID}`).update(updateobject).then(() => {
            dispatch(setProgress("FINISHED"));
            const $tdate = new Date();
            const taskdata ={
                "name": "Ticket Re-opened",
                "description": `Your query has been marked unsolved. Our team is on it and will get back to you at the earliest.`,
                "category": "Help & Support",
                "campaign": "",
                "priority": "low",
                "date": $tdate.getTime(),
                "sticky":false,
                "byline": "Freestand"
            }
            // dispatch(addTask(taskdata));
        }).catch(err => console.log(err));
    }
}




