import logo from '../../assets/img/sidebar/FS_Logo_White.png'
import sidebarBgImage from '../../assets/img/sidebar/rsz_1sidebar-4.jpg';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getTasksNum } from '../../redux/tasks/tasks.actions';
import {
  // UncontrolledTooltip,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { MdHome, MdPages, MdHelp, MdPlaylistAddCheck } from 'react-icons/md';

const navItems = [
  { to: '/dashboard', name: 'dashboard', exact: false, Icon: MdHome },
  { to: '/inventory/receival', name: 'Receive Inventory', exact: false, Icon: MdPages },
  // { to: '/inventory', name: 'Update Inventory', exact: false, Icon: MdPages },
  { to: '/help', name: 'help/support', exact: false, Icon: MdHelp },
];

const bem = bn.create('sidebar');

const Sidebar = ({tasksnum, notifsnum, getNum}) => {

  useEffect(() => {
    getNum();
  }, [getNum, tasksnum, notifsnum]);

  return (
    <aside  style = {{backgroundColor : "#052762"}} className={bem.b()} data-image={sidebarBgImage}>
    {/* <div className={bem.e('background')} style={sidebarBackground} /> */}
    <div className={bem.e('content')}>
      <Navbar>
          <img
            src={logo}
            width="100%"
            height="50"
            className="pr-2"
            alt=""
          />
          <h4>Logistics Manager</h4>
      </Navbar >

      <Nav vertical>
        {navItems.map(({ to, name, exact, Icon }, index) => (
          <NavItem key={index} className={bem.e('nav-item')}>
            <BSNavLink
              id={`navItem-${name}-${index}`}
              className="text-uppercase"
              tag={NavLink}
              to={to}
              activeClassName="active"
              exact={exact}
            >
              <Icon className={bem.e('nav-item-icon')} />
              <span className="">{name}</span>
            </BSNavLink>
          </NavItem>
        ))}
      </Nav>
    </div>
  </aside>
  );
}

const mapStateToProps = (state) => ({
  tasksnum: state.tasks.tasksnum,
  notifsnum: state.tasks.notifsnum
});

const mapDispatchToProps = (dispatch) => ({
  getNum: () => dispatch(getTasksNum())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);