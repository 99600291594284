const defaultState = {}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_INVEN_STATS':
        return {
            ...state,
            stats: action.stats
        }
        default:
            return state
    }
}