import React, { useState } from 'react';
import { createStructuredSelector } from 'reselect';
import Lottie from 'react-lottie';
import _ from 'lodash';
import { Modal, ModalBody, Row, Col } from 'reactstrap';

import CustomButton from '../CustomButton';
import ModalSuccess from '../modal-success/modal-success.component';
import ModalFail from '../modal-failure/modal-failure.component';

import { inventoryReceiveStart } from '../../redux/inventory/inventory.actions';
import animationData from '../../assets/lottie/17784-analyzing-website.json';
import './inventory-receive-form.styles.scss';
import { connect } from 'react-redux';
import { selectIsInventoryReceived, selectInventoryError } from '../../redux/inventory/inventory.selectors';

const InventoryReceiveForm = ({ inventoryReceiveStart, focussedInventory, isInventoryReceived, errorMessage }) => {
    const [modal, setModal] = useState(false);
    const [modalState, setModalState] = useState(null);
    const [modalError, setModalError] = useState(null);
    const [inputs, setInputs] = useState([
        { name: 'quantity', value: 0, error: '' },
        { name: 'master_carton_qty', value: 0, error: '' },
        { name: 'quantity_per_carton', value: 0, error: '' },
        { name: 'weight', value: 0, error: '' }
    ]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const modalToggle = () => {
        setModal(!modal);
        setModalState('load');
    }

    const handleInputChange = (idx, e) => {
        const { name, value } = e.target;
        let error = '';

        if (isNaN(value)) {
          error = `${name} field can only be number`
        }
    
        if (!value) {
          error = `${name} field cannot be empty`
        }

        inputs[idx] = {
            ...inputs[idx],
            value: value,
            error
        }

        setInputs([...inputs]);
    }

    const handleSubmit = e => {
        e.preventDefault();
        modalToggle();
        
        // check for empty fields
        //TODO add verification of fields if you feel like it
        const emptyFields = _.filter(inputs, { value: '' });
        if(emptyFields.length !== 0) {
            setModalState('fail');
            setModalError(`The following fields are not filled: ${emptyFields.map(field => field.name)}`);
        } else {
            inventoryReceiveStart({ inventory_id: focussedInventory.id, quantity: inputs[0].value });
            if (isInventoryReceived) {
                setModalState('success');
            }
            if (!!errorMessage) {
                setModalState('fail');
                setModalError(errorMessage);
            }
        }
    }

    return (
        <div className="inventory-receival-form">
            <form>
                {inputs.map((input, idx) => (
                    <Row key={idx}>
                        <Col>
                            <label htmlFor={input.name}>{input.name.replace(/_/g, ' ').toUpperCase()}</label>
                        </Col>
                        <Col>
                            <input required type="number" min={0} value={input.value} onChange={(e) => handleInputChange(idx, e)}/>
                        </Col>
                        <Col>
                            {input.error && <span className="text-danger">{input.error}</span> }
                        </Col>
                    </Row>
                ))}    
            <div className="custom-button-container">
                <CustomButton type="submit" onClick={handleSubmit}>Record Receival</CustomButton>
            </div>
        </form>
        {modal && (
            <Modal
            isOpen={modal} 
            toggle={modalToggle}
            centered={true}
            backdrop={'static'}
            className="order-creation-panel__modal"
            >
                <ModalBody>
                {!isInventoryReceived && (
                    <div className="inventory-animation">
                        <Lottie options={defaultOptions} />
                        <h5>Verifying Receival with FS Databases...</h5>
                    </div>
                )}
                {isInventoryReceived && (
                    <ModalSuccess closeFunc={modalToggle}>
                        <h5 className="text-success ml-2">Inventory Verified & Received Successfully.</h5>
                    </ModalSuccess>
                )}
                {(modalState === 'fail' || !!errorMessage) && (
                    <div>
                        <ModalFail closeFunc={modalToggle}>
                            <span color="danger">{modalError}</span>
                        </ModalFail>
                    </div>
                )}
                </ModalBody>
            </Modal>
        )}
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    isInventoryReceived: selectIsInventoryReceived,
    errorMessage: selectInventoryError
});

const mapDispatchToProps = dispatch => ({
    inventoryReceiveStart: (formData) => dispatch(inventoryReceiveStart(formData))
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReceiveForm);