import React, { useState, useEffect } from 'react';
import {
    Card,
    CardDeck,
    CardBody,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
    Modal,
    ModalBody,
    ModalFooter
  } from 'reactstrap';

import {GoPackage} from 'react-icons/go';
import {RiLinksLine} from 'react-icons/ri';
import {AiTwotoneAppstore} from 'react-icons/ai';
import {GiCardRandom} from 'react-icons/gi';
import {MdKeyboardArrowLeft} from 'react-icons/md';

import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { addQuery, getCamps } from '../redux/queries/queries.actions';
import placementSuccess from '../assets/images/placement-success.gif';
import placementLoader from '../assets/images/placement-loader.gif';
  
const QueryForm = ({addthisQuery, progress, history}) => {

    const [actionOngoing, setAction] = useState(false);
    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");
    const [campname, setCamp] = useState("nocampaign");

    const NothingSelected = (category==="");
    const DangerSelected = (category==="orders");
    const PrimarySelected = (category==="inventory");
    const WarningSelected = (category==="lastmile");
    const InfoSelected = (category==="others");
    const inProgress = (progress!=="FINISHED" && progress!=="");
    const isFinished = progress==="FINISHED";

    const defaultmail = "";

    const ticketID = (Math.floor(new Date().getTime()/1000)).toString(36).toUpperCase();

    const categories = {
        "order": {
            "inventory": "Inventory Issues",
            "refusal": "Refusal by Client",
            "tracking": "Delivery Tracking"},
        "chatbot": {
            "message": "Change Messages Copy",
            "options": "Change Options Copy"
        },
        "billing": {
            "previous": "Issue with previous Billing",
            "current": "Get Current billing",
            "invoice": "Get Invoice"
        }
    }

    const handleSubmit = () => {
        const validform = document.querySelector('form').reportValidity();
        if(validform) {
            const $inputFields = document.querySelectorAll(".query_input");
            const $response = document.getElementById("response_input").value;
            const $uploadfile = document.getElementById("input_upload").files[0];
            const $querydata = {};
            $querydata["id"] = ticketID;
            $querydata['status'] = 'unsolved';
            $querydata['campaign'] = campname;
            $inputFields.forEach(field => $querydata[field.name]=field.value);
            $querydata["category"] = category;
            if($uploadfile!==undefined){
                addthisQuery($querydata, $response, $uploadfile);
            }
            else addthisQuery($querydata, $response);
            setAction(true);
        }
    }

    const handleCategory = (e) => {
        if(NothingSelected) setCategory(e.target.id);
        else {
            setCategory("");
        }
    }

    const handleReload = () => {
        setAction(false);
        history.push(`/help`);
    }

    return(
        <div className="bg-white p-4">
            <Link to="/help"><Button color="secondary"><MdKeyboardArrowLeft style={{fontSize: "1.5em"}} />Back</Button></Link>
            <span className="h4 float-right">TICKET ID: {ticketID}</span><br /><br />
            <Form className="bg-light p-4">
                <FormGroup>
                    <Label for="email">E-mail<span style={{color: "red"}}>*</span></Label>
                    <Input required placeholder="Where can we reach you" type="email" name="email" className="query_input" />
                </FormGroup>
                <FormGroup>
                    <Label for="question">Subject<span style={{color: "red"}}>*</span></Label>
                    <Input required type="text" name="question" placeholder="What is this query about" className="query_input" />
                </FormGroup>
                <Label>Select A Category</Label>
                <CardDeck>
                    {(NothingSelected || DangerSelected) && (
                    <Card style={{backgroundColor: "#ff6961"}}>
                        <CardBody id="orders" onClick={handleCategory} className="can-click d-flex align-items-center">
                            <span id="orders" onClick={handleCategory} style={{fontSize: NothingSelected?"1.4em":"2em"}}>
                                <GoPackage /> Orders
                            </span>
                        </CardBody>
                    </Card>)}
                    {(NothingSelected || PrimarySelected) && (
                    <Card style={{backgroundColor: "#757EFB"}}>
                        <CardBody id="inventory" onClick={handleCategory} className="can-click d-flex align-items-center">
                            <span id="inventory" onClick={handleCategory} style={{fontSize: NothingSelected?"1.4em":"2em"}}>
                                <AiTwotoneAppstore /> Inventory
                            </span>
                        </CardBody>
                    </Card>)}
                    {(NothingSelected || WarningSelected) && (
                    <Card style={{minWidth: "300px", backgroundColor: "#ebe534"}}>
                        <CardBody id="lastmile" onClick={handleCategory} className="can-click d-flex align-items-center">
                            <span id="lastmile" onClick={handleCategory} style={{fontSize: NothingSelected?"1.4em":"2em"}}>
                                <RiLinksLine /> Last Mile Connect
                            </span>
                        </CardBody>
                    </Card>)}
                    {(NothingSelected || InfoSelected) && (
                    <Card style={{backgroundColor: "#3bc48f"}}>
                        <CardBody id="others" onClick={handleCategory} className="can-click d-flex align-items-center">
                            <span id="others" onClick={handleCategory} style={{fontSize: NothingSelected?"1.4em":"2em"}}>
                                <GiCardRandom /> Other
                            </span>
                        </CardBody>
                    </Card>)}   
                </CardDeck><br />
                {(!NothingSelected) && (
                <div>
                    <FormGroup>
                        <Label for="response">Query<span style={{color: "red"}}>*</span></Label>
                        <Input
                            required
                            type="textarea"
                            name="response"
                            placeholder="Please tell us more about your issue"
                            id="response_input"
                        />
                    </FormGroup>
                    <FormGroup>
                        <div className="text-dark rounded mb-0 bg-light p-2">
                            Upload Files to help us understand your query better<br />
                            <input type="file" accept="image/*,.pdf" id="input_upload"/>
                        </div>
                    </FormGroup>
                    <Button onClick={handleSubmit}>Submit</Button>
                </div>)}
            </Form>
            {inProgress && (
                <Modal isOpen={true} backdrop="static" centered={true}>
                    <center>
                        <img src={placementLoader} alt="placement loader gif" width="180" height="180" />
                        <h4>Please wait...</h4>
                    </center>
                </Modal>
            )}
            {isFinished && (
                <Modal isOpen={actionOngoing} backdrop="static" centered={true}>
                    <ModalBody>
                        <center>
                            <img src={placementSuccess} alt="placement success gif" width="180" height="180" />
                            <h4>Success!</h4>
                        </center>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleReload}>Go back</Button>
                    </ModalFooter>
                </Modal>
            )}
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    addthisQuery: ($querydata, $response, $filename) => 
        dispatch(addQuery($querydata, $response, $filename))
});

const mapStateToProps = (state) => ({
    progress: state.queries.progress
});

const RoutedQueryForm = withRouter(QueryForm);

export default connect(mapStateToProps, mapDispatchToProps)(RoutedQueryForm);