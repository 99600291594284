/*
    Name of Component: Profile Card
    Purpose: Displays the user profile
    @params:
        header: String
        data: Object([Array])
        bgColor: String
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody } from 'reactstrap';

const ProfileCard = ({ header, data, bgColor }) => {
    return (
        <Card color={bgColor}>
            <CardHeader>{`${header.toUpperCase()} Details`}</CardHeader>
            <CardBody>
                {Object.keys(data).map((key, index) => {
                    if (data[key] !== undefined) {
                        return (<p key={index}><b>{key.toUpperCase()}:</b> <span>{data[key].toString().toUpperCase()}</span></p>);
                    } else {
                        return (<p key={index}><b>{key.toUpperCase()}:</b></p>)
                    }
                })}
            </CardBody>
        </Card>
    );
};

ProfileCard.propTypes = {
    header: PropTypes.string,
    data: PropTypes.object,
    bgColor: PropTypes.string
};

export default ProfileCard;