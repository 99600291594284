import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import OdometerComponent from '../components/OdometerComponent';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { setSelectedRecord } from '../redux/vendor/vendor.actions';

const MonitorOrderTable = ({
    columnDefs, 
    rowData, 
    actions,
    setRowSelection
}) => {

    const [activeRows, setActiveRows] = useState(rowData);

    const [gridApi, setGridApi] = useState();
    // const [columnApi, setColumnApi] = useState();

    const handleSelectionChange = () => {
        const selectedRows = gridApi.getSelectedRows();
        const selectedRecord = {};
        Object.keys(selectedRows[0]).forEach(key => selectedRecord[key] = selectedRows[0][key]);
        setRowSelection(selectedRecord);
    }

    return (
        <div className="vendor-table">
            <h3 className="text-primary">Number of Records: <span className="text-secondary"><OdometerComponent value={activeRows.length} /></span></h3>
            <div className="ag-theme-alpine mb-2" style={{ height: '50vh' }}>
                <AgGridReact
                    onGridReady={params => {
                        setGridApi(params.api);
                        // setColumnApi(params.columnApi);
                    }}
                    columnDefs={columnDefs}
                    rowSelection="single"
                    rowData={rowData}
                    animateRows // can be turned to false, to improve performance when working on large datasets
                    // pagination={true}
                    // paginationAutoPageSize={true}
                    defaultColDef={{
                        filter: true,
                        sortable: true,
                        resizable: true,
                        width: 200,
                        suppressSizeToFit: false,
                        suppressMovable: true,
                        cellRenderer: params => {
                            if (params.value.isLink) {
                                var actionLink = document.createElement('a');
                                actionLink.href = params.value.url;
                                actionLink.target = '_blank';
                                actionLink.innerHTML = `${params.value.title}`  // to access the value that is to be displayed
                                return actionLink;
                            } else if (params.column.colId === 'upload_receiving') {
                                var actionnBtn = document.createElement('button');
                                actionnBtn.innerText = 'Upload'; // to access the value that is to be displayed
                                actionnBtn.className = 'btn btn-primary';
                                actionnBtn.onclick = () => {
                                    actions.modalToggle();
                                }
                                if (params.data.processed) {
                                    actionnBtn.disabled = true;
                                }
                                return actionnBtn;
                            } else if (params.column.colId === 'upload_date_and_time') {
                                return new Date(Number(params.value)).toLocaleString();
                            } else if (params.column.colId === 'generate_receiving') {
                                let generateBtn = document.createElement('button');
                                generateBtn.innerText = 'Generate'; 
                                generateBtn.className = 'btn btn-primary';
                                generateBtn.onclick = () => {
                                    actions.pdfToggle();
                                }
                                if (params.data.processed) {
                                    generateBtn.disabled = true;
                                }
                                return generateBtn;
                            }
                            return params.value;
                        },
                    }}
                    onSelectionChanged={handleSelectionChange}
                    onFilterChanged={() => {
                        let rowsAfterFilter = [];
                        gridApi.forEachNodeAfterFilter(({ data }) => {
                            rowsAfterFilter.push(data);
                        });
                        setActiveRows(rowsAfterFilter);
                    }}
                    onRowDataChanged={() => {
                        setActiveRows(rowData);
                    }}
                >
                </AgGridReact>
            </div>
        </div>
    );
}

MonitorOrderTable.propTypes = {
    columnDefs: PropTypes.array.isRequired,
    rowData: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    setRowSelection: (selectedRecord) => dispatch(setSelectedRecord(selectedRecord))
});

export default connect(undefined, mapDispatchToProps)(MonitorOrderTable);