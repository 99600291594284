import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectScheduledInventory, selectIsInventoryFetching } from '../../redux/inventory/inventory.selectors';
import WithSpinner from '../with-spinner/with-spinner.component';
import ScheduledInventoryList from './scheduled-inventory-list.component';

const mapStateToProps = createStructuredSelector({
    scheduledInventory: selectScheduledInventory,
    isLoading: selectIsInventoryFetching
});

const ScheduledInventoryListContainer = compose(
    connect(mapStateToProps),
    WithSpinner
)(ScheduledInventoryList);

export default ScheduledInventoryListContainer;