import inventoryActionTypes from './inventory.types';

export const fetchInventoryStart = () => ({
    type: inventoryActionTypes.FETCH_INVENTORY_START,
});

export const fetchInventorySuccess = inventory => ({
    type: inventoryActionTypes.FETCH_INVENTORY_SUCCESS,
    payload: inventory
});

export const fetchInventoryFailure = errorMessage => ({
    type: inventoryActionTypes.FETCH_INVENTORY_FAILURE,
    payload: errorMessage
});

export const inventoryReceiveStart = formData => ({
    type: inventoryActionTypes.INVENTORY_RECEIVE_START,
    payload: formData
});

export const inventoryReceiveSuccess = () => ({
    type: inventoryActionTypes.INVENTORY_RECEIVE_SUCCESS
});

export const inventoryReceiveFailure = errorMessage => ({
    type: inventoryActionTypes.INVENTORY_RECEIVE_FAILURE,
    payload: errorMessage
});