import database, { firebase } from '../../firebase/firebase';

export const setInventoryStats = (stats) => ({
    type: 'SET_INVEN_STATS',
    stats
})

export const getInventoryStats = () => {
    return (dispatch, getState) => {
        const { clients } = getState().vendor.profile;
        const firstClientKey = Object.keys(clients)[0];
        const firstCampaign = clients[firstClientKey]['campaigns'][0];
        database.ref(`users/${firstClientKey}/inventory/statistics/${firstCampaign}/overall`)
        .once('value').then(snapshot => {
            if (snapshot.exists()) dispatch(setInventoryStats(snapshot.val()));
            else dispatch(setInventoryStats({}))
        })
        .catch(err=>{
            console.log(err);
        })
    }
}

export const updateInventoryStats = (newvals) => {
    return (dispatch, getState) => {
        const { clients } = getState().vendor.profile;
        const firstClientKey = Object.keys(clients)[0];
        const firstCampaign = clients[firstClientKey]['campaigns'][0];
        const updateObj = {};
        if(newvals['rto']) updateObj['rto'] = firebase.database.ServerValue.increment(newvals['rto']);
        if(newvals['damaged']) updateObj['damaged'] = firebase.database.ServerValue.increment(newvals['damaged']);
        database.ref(`users/${firstClientKey}/inventory/statistics/${firstCampaign}/overall`)
        .update(updateObj).then(() => dispatch(getInventoryStats()))
        .catch(err => console.log(err))
    }
}