import database, { storage } from '../../firebase/firebase';
import { forEach } from 'async-foreach';

//SET_IS_LOADING
export const setIsLoading = (isloading) => ({
    type: 'SET_IS_LOADING',
    isLoading: isloading
});

//SET_VENDOR_ASSETS
export const setVendorAssets = (assets = []) => ({
    type: 'SET_VENDOR_ASSETS',
    assets
});

//SET_USER_PROFILE 
export const setUserProfile = (profile = {}) => ({
    type: 'SET_USER_PROFILE',
    profile
});

export const SetSelectedClient = (client_id, camp="") => {
    return (dispatch, getState) => {
        let profile = getState().vendor.profile;
        profile['client_id'] = client_id;
        if(camp!="") profile['campaign'] = camp;
        dispatch(setUserProfile(profile));
    }
}

export const startSetUserProfile = () => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        const userRef = database.ref(`vendors/users/${uid}`);
        return userRef.once('value').then(snapshot => {
            if (snapshot.exists()) {
                dispatch(setUserProfile(snapshot.val()));
            } else {
                dispatch(setUserProfile());
            }
        });
    }
}

export const startGetVendorAssets = () => {
    return (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const { client_id, campaign, region, vendor_id } = getState().vendor.profile;
        const packageRef = database.ref(`vendors/companies/${vendor_id}/${region}/${client_id}/${campaign}/processed-packaging-slips`);
        packageRef.once('value').then(snapshot => {
            const processedPackagingSlips = snapshot.exists() ? Object.keys(snapshot.val()) : [];
            if (region==="All India (Delhi)")
            {
                const storageRef = storage.ref(`/${client_id}/${campaign}/packaging-slips/`);
                const packagingSlips = [];
                storageRef.listAll()
                .then(({ prefixes }) => {
                    let myPromises = prefixes.map(folderRef => {
                        return new Promise((resolve) => {
                            storageRef.child(`${folderRef.name}/${vendor_id}`).listAll()
                            .then(({ items }) => {
                                items.forEach(itemRef => {
                                    itemRef.getDownloadURL().then(url => {
                                        const fileName = itemRef.location.path_.match(/\d+_\w+[-_ ]?\w+/g)[0];
                                        const uploadTimestamp = fileName.split('_')[0];
                                        const uploadSku = fileName.split('_')[1].toUpperCase();
                                        const waybillCount = fileName.split('_')[2];
                                        const result = {
                                            packaging_slips: `FS ${campaign} ${region} - packaging slips`.toUpperCase(),
                                            upload_date_and_time: uploadTimestamp,
                                            sku: uploadSku,
                                            waybill_count: waybillCount,
                                            download: {
                                                title: 'Download',
                                                url,
                                                isLink: true,
                                            },
                                            generate_receiving: '',
                                            upload_receiving: '',
                                            processed: false
                                        };
                                        console.log(result);
                                        packagingSlips.push(result);
                                        resolve();
                                    })
                                })
                            });
                        })
                    });
                    Promise.all(myPromises).then(() => {
                        console.log(packagingSlips)
                        dispatch(setVendorAssets(packagingSlips.map(slip => {
                            if(processedPackagingSlips.includes(`${slip.upload_date_and_time}_${slip.sku}_${slip.waybill_count}`)) {
                                return { ...slip, processed: true }
                            }
                            return slip;
                        })))
                        dispatch(setIsLoading(false));
                    })
                })
            } else {
                const storageRef = storage.ref(`/${client_id}/${campaign}/packaging-slips/${region}/${vendor_id}`);
                const packagingSlips = [];
                storageRef.listAll()
                .then(({ items }) => {
                    forEach(items, function(itemRef) {
                        const done = this.async();
                        itemRef.getDownloadURL()
                        .then((url) => {
                            const fileName = itemRef.location.path_.match(/\d+_\w+[-_ ]?\w+/g)[0];
                            const uploadTimestamp = fileName.split('_')[0];
                            const uploadSku = fileName.split('_')[1].toUpperCase();
                            const waybillCount = fileName.split('_')[2];
                            packagingSlips.push({
                                packaging_slips: `FS ${campaign} ${region} - packaging slips`.toUpperCase(),
                                upload_date_and_time: uploadTimestamp,
                                sku: uploadSku,
                                waybill_count: waybillCount,
                                download: {
                                    title: 'Download',
                                    url,
                                    isLink: true,
                                },
                                generate_receiving: '',
                                upload_receiving: '',
                                processed: false
                            });
                        })
                        .then(() => done())
                        .catch(err => console.log(err));
                    }, () => {
                        dispatch(setVendorAssets(packagingSlips.map(slip => {
                            if(processedPackagingSlips.includes(`${slip.upload_date_and_time}_${slip.sku}_${slip.waybill_count}`)) {
                                return { ...slip, processed: true }
                            }
                            return slip;
                        })))
                        dispatch(setIsLoading(false));
                    } )
                })
            }
        }).catch(err => {
            console.log(err);
            dispatch(setVendorAssets());
            dispatch(setIsLoading(false));
        });
    }
};

// SET_SELECTED_RECORD
export const setSelectedRecord = selectedRecord => ({
    type: 'SET_SELECTED_RECORD',
    selectedRecord
});

export const updateVendorRecords = () => {
    return (dispatch, getState) => {
        const { client_id, campaign, region, vendor_id } = getState().vendor.profile;
        const { sku, waybill_count, upload_date_and_time } = getState().vendor.selectedRecord;
        const dbRef = database.ref(`vendors/companies/${vendor_id}/${region}/${client_id}/${campaign}/processed-packaging-slips`);
        return dbRef.update({
            [`${upload_date_and_time}_${sku}_${waybill_count}`]: true
        })
    }
}