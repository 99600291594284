import React from 'react';
import {
  MdClearAll
} from 'react-icons/md';
import {
  Button,
  Nav,
  Navbar
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { startLogout } from '../../redux/auth/auth.actions';
import { connect } from 'react-redux';

const bem = bn.create('header');

const Header = ({ startLogoutAsync }) => {
  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  return (
    <Navbar light expand className={bem.b('bg-white')}>
      {/* <Nav navbar className="mr-2">
        <Button outline onClick={handleSidebarControlButton}>
          <MdClearAll size={25} />
        </Button>
      </Nav> */}
      <Nav navbar className={bem.e('nav-right')}>
        <button className="custom-button" onClick={startLogoutAsync}>Logout</button>
      </Nav>
    </Navbar>
  );
}

const mapDispatchToProps = dispatch => ({
    startLogoutAsync: () => dispatch(startLogout())
});

export default connect(undefined, mapDispatchToProps)(Header);