import React, { useEffect, useState } from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardHeader,
    Spinner,
    CardColumns
} from 'reactstrap';
import { connect } from 'react-redux';
import { getInventoryStats, updateInventoryStats } from '../redux/inventoryform/inven.actions';

const InventoryForm = ({ getStats, updateStats, stats }) => {
    useEffect(() => {
        getStats();
    }, [getStats]);

    const [bt, setBt] = useState(false);

    const handleSubmit = () => {
        setBt(true);
        const result = {};
        const inputFields = document.querySelectorAll(".query_input");
        inputFields.forEach(field => {
            if(field.value!=="")
                result[field.name] = parseInt(field.value);
        });
        updateStats(result);
        window.setTimeout(() => {
            setBt(false);
            inputFields.forEach(field => field.value="");
        }, 1500);
    }

    return (
        <div style={{width: '80%', marginTop: '30px'}}>
            <Card style={{margin: '10px 5%'}}>
                <CardHeader>Current Inventory Statistics</CardHeader>
                <CardBody>
                   <CardColumns>
                        {stats?
                            <>
                            {Object.keys(stats).map(key =>
                                <Card key={key}>
                                    <CardHeader style={{background: '#f7f7f7'}}>{key}</CardHeader>
                                    <CardBody style={{textAlign: 'center'}}>{stats[key]}</CardBody>
                                </Card>
                            )}
                            <Card>
                                <CardHeader style={{background: '#f7f7f7'}}>LEFT</CardHeader>
                                <CardBody style={{textAlign: 'center'}}>
                                    {
                                        stats[Object.keys(stats).filter(val =>
                                            val==='allocated'
                                        )[0]] - Object.keys(stats).filter(val => 
                                            val!=='allocated'
                                        ).reduce((val, current) => val + stats[current], 0)
                                    }
                                </CardBody>
                            </Card>
                            </> : <Spinner />}
                    </CardColumns>
                </CardBody>
            </Card>
            <Form style={{margin: '10px 5%', padding: '40px', background: 'white'}}>
                <Row>
                    <Col>
                    <FormGroup>
                        <Label for="rto">RTO</Label>
                        <Input required placeholder="Packages Returned to Origin" type="number" name="rto" className="query_input" />
                    </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="damaged">Damaged</Label>
                            <Input required type="number" name="damaged" placeholder="Packages Damaged" className="query_input" />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: 'center'}}>
                        {bt?
                            <Spinner /> :
                            <Button onClick={handleSubmit}>ADD to Current</Button>
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    getStats: () => dispatch(getInventoryStats()),
    updateStats: (obj) => dispatch(updateInventoryStats(obj))
});

const mapStateToProps = (state) => ({
    stats: state.inventoryform.stats
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryForm);