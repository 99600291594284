import React from 'react';

import SignInComponent from '../components/Signin';

export default () => {
    return (
        <div className="sign-in-and-sign-up">
            <SignInComponent />
        </div>
    );
}