import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { firebase } from './firebase/firebase';
import { store } from './redux/store/store';
import { login, logout } from './redux/auth/auth.actions';
import { startGetVendorAssets, startSetUserProfile } from './redux/vendor/vendor.actions';
import { loadFirebaseAuthToken } from './redux/firebase/firebase.actions';

import LoadingPage from './pages/LoadingPage';
import AppRouter, { history } from './routers/AppRouter';

const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('root'));
    hasRendered = true;
  }
};

ReactDOM.render(<LoadingPage />, document.getElementById('root'));

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    user.getIdTokenResult().then((res) => {
      if(res.claims.vendor)
      {
        store.dispatch(login(user.uid));
        store.dispatch(loadFirebaseAuthToken())
        store.dispatch(startSetUserProfile())
        // .then(() => {
        //   store.dispatch(startGetVendorAssets());
        // })
        .then(() => {
          renderApp();
          if (history.location.pathname === '/') {
            history.push('/dashboard');
          }
        })
      }
      else window.alert("Permission Denied")
    })
  }else {
    store.dispatch(logout());
    renderApp();
    history.push('/');
  }
});