import { FIREBASE_AUTH } from './firebase.types';

const INITIAL_STATE = {
    auth_token: '',
    isLoading: false,
    error: ''
}

const firebaseReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FIREBASE_AUTH.LOAD:
            return {
                ...state,
                isLoading: true
            }
        case FIREBASE_AUTH.FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case FIREBASE_AUTH.SUCCESS:
            return {
                ...state,
                isLoading: false,
                auth_token: action.payload
            }
        default:
            return state;
    }
}

export default firebaseReducer;