import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, Alert, Input, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { startGetVendorAssets, setVendorAssets, updateVendorRecords } from '../redux/vendor/vendor.actions';
import { startFileUpload, setIsUploaded } from '../redux/form/form.actions';

import ProfileCard from '../components/ProfileCard';
import MonitorOrderTable from '../components/MonitorOrderTable';
import UploadComponent from '../components/UploadComponent';
import UploadProcess from '../components/UploadProcess';
import PageSpinner from '../components/PageSpinner';
import Warehouse from '../components/Warehouse';
import Page from '../components/Page';
import CustomButton from '../components/CustomButton';
import ControlPanel from '../components/Layout/ControlPanel';

const VendorsPage = ({
    assets, 
    profile,
    isLoading,
    selectedRecord,
    startUploading, 
    uploadProgress,
    uploadAlert, 
    setUploadStatus,
    vendorRecordUpdates,
    getVendorAssets
}) => {
    const [columnDefs] = useState([
        { headerName: 'PACKAGING SLIPS', field: 'packaging_slips' },
        { headerName: 'NUMBER OF WAYBILLS', field: 'waybill_count' },
        { headerName: 'UPLOAD DATE & TIME', field: 'upload_date_and_time', sortingOrder: ['asc', 'desc'] },
        { headerName: 'SKU', field: 'sku' },
        { headerName: 'DOWNLOAD PACKAGING SLIPS', field: 'download' },
        { headerName: 'GENERATE RECEIVING SLIP', field: 'generate_receiving' },
        { headerName: 'UPLOAD RECEIVING SLIP', field: 'upload_receiving' },
    ]);

    const [modal, setModal] = useState(false);
    const [modalState, setModalState] = useState(null);
    const [data, setData] = useState({});
    // useEffect(() => () => resetVendorAssets(), [resetVendorAssets]);

    const toggle = () => {
        setModal(!modal);
        setModalState(null);
        if (modal) {
            setUploadStatus();
        }
    }
    useEffect(() => {
        const { name , position , region, vendor_id } = profile;
        setData({name , position , region, vendor_id});
    },[setData, profile]);

    
  

    const [pdfmodal, setPdfmodal] = useState(false);
    const formToggle = () => setPdfmodal(!pdfmodal);

    const handleUpload = () => {
        const createdAt = Date.now();
        console.log(selectedRecord)
        const { sku, waybill_count } = selectedRecord;
        const { client_id, campaign, region, vendor_id } = profile;
       
        vendorRecordUpdates()
        .then(() => {
            startUploading(
                `${createdAt}_${sku}_${waybill_count}.pdf`, 
                `${client_id}/${campaign}/receiving-slips/${region}/${vendor_id}`,
                 () => {
                    toggle();
                    getVendorAssets();
                 }
            );
        })
        .catch(err => console.log('Error uploading!'));
    };

    return (
        <Page>
            <div className="vendor-table">
                <ControlPanel onComplete={getVendorAssets} />
                <div style={{marginTop: "35px"}}>
                    <ProfileCard header="User Profile" data={data} bgColor="white" />
                </div>
                <Card>
                    <CardHeader>CAMPAIGN: {profile.campaign}</CardHeader>
                </Card>
                {isLoading && <div className="page-spinner"><PageSpinner /></div>}
                { assets.length !== 0 ? (
                    <div>
                        <MonitorOrderTable
                            columnDefs={columnDefs}
                            rowData={assets}
                            actions={{
                                modalToggle: toggle,
                                pdfToggle: formToggle
                            }}
                        />
                    </div>
                ) : (<Alert color="warning" className="mt-2">No Records Found!</Alert>) }
                <Modal isOpen={pdfmodal} toggle={formToggle} centered={true}>
                    <ModalBody>
                        <Warehouse />
                    </ModalBody>
                </Modal>
                {modal && (
                    <Modal
                    isOpen={modal} 
                    toggle={toggle}
                    centered={true}
                    backdrop={'static'}
                    className="order-creation-panel__modal"
                    >
                        <ModalBody>
                        {modalState === null && (                            
                            <div className="mb-3 border border-primary" >
                                <UploadComponent handleUpload={handleUpload} />
                                <UploadProcess uploadProgress={uploadProgress} uploadAlert={uploadAlert} />
                                { uploadProgress === 0 && <div className="custom-button-container">
                                    <CustomButton theme="danger" type="submit" onClick={toggle}>Close</CustomButton>
                            </div> }
                            </div>
                        )}
                        </ModalBody>
                    </Modal>
                )}
            </div>
        </Page>
    );
};

const mapStateToProps = (state) => ({
    assets: state.vendor.assets,
    selectedRecord: state.vendor.selectedRecord,
    profile: state.vendor.profile,
    isLoading: state.vendor.isLoading,
    uploadProgress: state.form.uploadProgress,
    uploadAlert: state.form.uploadAlert,
});

const mapDispatchToProps = (dispatch) => ({
    getVendorAssets: () => dispatch(startGetVendorAssets()),
    resetVendorAssets: () => dispatch(setVendorAssets()),
    vendorRecordUpdates: () => dispatch(updateVendorRecords()),
    setUploadStatus: () => dispatch(setIsUploaded(false)),
    startUploading: (fileName, storageLocation, callback) => dispatch(startFileUpload(fileName, storageLocation, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorsPage);