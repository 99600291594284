import { createSelector } from 'reselect';
import { filterInventoryByStatus } from './inventory.util';

export const selectInventory = state => state.inventory;

export const selectInventoryStatus = createSelector(
    [selectInventory],
    inventory => inventory.status ? Object.keys(inventory.status).map(key => ({ id: key, ...inventory.status[key] })) : []
);

export const selectIsInventoryFetching = createSelector(
    [selectInventory],
    inventory => inventory.isFetching
);

export const selectIsInventoryReceived = createSelector(
    [selectInventory],
    inventory => inventory.isInventoryReceived
);

export const selectInventoryError = createSelector(
    [selectInventory],
    inventory => inventory.errorMessage
);

export const selectScheduledInventory = createSelector(
    [selectInventoryStatus],
    inventory => filterInventoryByStatus(inventory, 'scheduled')
);