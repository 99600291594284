import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import CustomButton from './CustomButton';

const UploadComponent = ({ handleUpload, isUploaded }) => {
    return (
        <div className="mt-2 pl-2">
            <Row>
                <Col xl={12} md={12} sm={12} xs={12}>
                    <input type="file" accept=".pdf" name="upload-input" id="upload-input" className="text-secondary" disabled={isUploaded} />
                </Col>
                <Col xl={12} md={12} sm={12} xs={12}>
                    <CustomButton theme="primary" onClick={handleUpload} disabled={isUploaded}>Upload</CustomButton>
                </Col>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isUploaded: state.form.isUploaded
});

export default connect(mapStateToProps)(UploadComponent);