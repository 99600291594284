import React, { useEffect } from 'react';
import Lottie from 'react-lottie';

import Page from '../../components/Page';

import "./inventory-receival-page.styles.scss";
import animationData from '../../assets/lottie/26057-warehouse-with-location.json';
import ScheduledInventoryListContainer from '../../components/scheduled-inventory-list/scheduled-inventory-list.container';
import { Row, Col, Jumbotron } from 'reactstrap';
import { fetchInventoryStart } from '../../redux/inventory/inventory.actions';
import { connect } from 'react-redux';
import InventoryDetails from '../../components/inventory-details/inventory-details.component';

const InventoryReceivalPage = ({ fetchInventoryStart }) => {
    useEffect(() => {
        fetchInventoryStart();
    }, [fetchInventoryStart]);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Page>
            <Row className="mt-2">
                <Col xl={3} lg={3}>
                    <ScheduledInventoryListContainer />
                </Col>
                <Col xl={9} lg={9}>
                    <Jumbotron>
                        <Row>
                            <Col xl={4} lg={4}>
                                <div className="details-section">
                                    <div className="inventory-animation">
                                        <Lottie options={defaultOptions} />
                                    </div>
                                </div>                        
                            </Col>
                            <Col xl={8} lg={8}>
                                <div className="receival-form">
                                    <InventoryDetails />
                                </div>                        
                            </Col>
                        </Row>                    
                    </Jumbotron>                
                </Col>
            </Row>
        </Page>
    );
};

const mapDispatchToProps = dispatch => ({
    fetchInventoryStart: () => dispatch(fetchInventoryStart())
});

export default connect(undefined, mapDispatchToProps)(InventoryReceivalPage);