import React from 'react';
import { withRouter } from 'react-router-dom';

import './scheduled-inventory-list.styles.scss';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { AiOutlineRight } from 'react-icons/ai';

const ScheduledInventoryOverview = ({ scheduledInventory, history }) => {
    return (
        <div>
            <h4 className="ml-2">Scheduled Inventory</h4>
            <h6 className="text-secondary ml-2">Select one to get inventory details and generate receiving</h6>
            <ListGroup className="inventory-list">
                {scheduledInventory.map((item, index) => <ListGroupItem key={item.id} onClick={() => history.push(`?id=${item.id}`)} action className="d-flex justify-content-between">Inventory - {index+1} <span><AiOutlineRight /></span></ListGroupItem>)}
            </ListGroup> 
        </div>
    );
};

export default withRouter(ScheduledInventoryOverview);