import { takeEvery, call, put, delay, select } from 'redux-saga/effects';
import { FIREBASE_AUTH } from './firebase.types';
import { firebase } from '../../firebase/firebase';
import { setFirebaseAuthToken, setFirebaseAuthError } from './firebase.actions';

const fetchAuthToken = () => {
    return new Promise((resolve, reject) => {
        // get the token
        const user = firebase.auth().currentUser;
        user.getIdToken(true).then((token) => {
            resolve(token);
        })
        .catch(error => {
            reject(error);
        })
    });
}

function* handleFirebaseAuthLoad() {
    // This is an infinite loop which loops after a designated delay.
    // The delay is 3480000s or 58 minutes (as token expires in 60 minutes)
    while(true) {
        try {
            // The select effect takes a function which returns the required value from the redux store
            // We call the function which gets the auth token
            const auth_token = yield call(fetchAuthToken);
            // Once it is fetched, the action is triggered which sets the token to the store
            yield put(setFirebaseAuthToken(auth_token));
        }
        catch(error) {
            // In case of any error, the action is triggered which sets the error object in the store
            yield put(setFirebaseAuthError(error));
        }
        yield delay(3480000);
    }

}

// The watcher function that fires a function whenever the firebase auth load action is triggered
function* watchFirebaseAuthLoad() {
    yield takeEvery(FIREBASE_AUTH.LOAD, handleFirebaseAuthLoad)
}

export default watchFirebaseAuthLoad;
